<template>
    <div class="employee-info" v-if="info">
        <div class="el-content mb12">
            <a-button class="back-btn" @click="$router.go(-1)">返回</a-button>
            <div class="employee-info-title">基本信息</div>
            <a-row :gutter="[48,48]" v-if="info">
                <a-col :span="2">
                    <div class="ei-avartar">
                        <img v-if="info.pic_url" :src="info.pic_url " alt="">
                        <img v-else src="@/assets/img/moren.png" alt="">
                        <div class="ei-avartar-update" @click="showUpdate(4)">修改</div>
                    </div>
                </a-col>
                <a-col :md="11" :lg="11" :xl="9" :xxl="8">
                    <div class="ei-item">
                        <div class="ei-item-title">昵称：</div>
                        <div class="ei-item-val">{{ info.name }}</div>
                        <a-button class="ei-update-btn" type="link" @click="showUpdate(1)">修改</a-button>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">密码：</div>
                        <div class="ei-item-val">******</div>
                        <a-button class="ei-update-btn" type="link" @click="showUpdate(5)">修改</a-button>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">手机号：</div>
                        <div class="ei-item-val">{{ info.mobile }}</div>
                        <a-button class="ei-update-btn" type="link" @click="showUpdate(2)">修改</a-button>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">角色：</div>
                        <div class="ei-item-val" style="font-size:13px">{{ userRoleTxt }}</div>
                        <a-button class="ei-update-btn" type="link" @click="showSetRole">设置</a-button>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">邮箱：</div>
                        <div class="ei-item-val">{{ info.email }}</div>
                        <a-button class="ei-update-btn" type="link"  @click="showUpdate(3)">修改</a-button>
                    </div>
                </a-col>
                <a-col :md="11" :lg="11" :xl="9" :xxl="8">
                    <div class="ei-item" >
                        <div class="ei-item-title">登陆账号：</div>
                        <div class="ei-item-val">{{ info.name}}@{{ info.master_name }}</div>
                    </div>
                    <div class="ei-item" >
                        <div class="ei-item-title">最后登录：</div>
                        <div class="ei-item-val">{{ info.login_time }}</div>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">最后登录IP：</div>
                        <div class="ei-item-val">{{ info.last_login_ip }}</div>
                    </div>
                    <div class="ei-item">
                        <div class="ei-item-title">绑定微信：</div>
                        <div class="ei-item-val">{{ info.openid ?'未绑定':'已绑定'}}</div>
                    </div>
                </a-col>
            </a-row>
        </div>

        <div class="el-content">
            <div class="employee-info-title">
                农场店铺信息
            </div>
            <div class="ei-farm">
                <div class="ei-farm-item" v-for="(item,index) in farmState.list" :key="index">
                    <img class="eifi-logo" :src="item.logo" alt="">
                    <div class="eifi-name">{{ item.program_name }}</div>
                </div>
                <div class="ei-farm-add" @click="showSetFarm">
                    <i class="ri-add-line ri-xl"></i>
                    <p>分配店铺</p>
                </div>
            </div>
        </div>

        <a-modal title="修改信息" 
            v-model:visible="employeeState.show" 
            width="600px" 
            @ok="saveEmployee" 
            @cancel="employeeState.show = false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" v-if="employeeState.form">
				<a-form-item label="昵称" v-if="employeeState.update_type == 1">
                    <a-input v-model:value="employeeState.form.name"></a-input>
                </a-form-item>
                <a-form-item label="手机号" v-if="employeeState.update_type == 2">
                    <a-input v-model:value="employeeState.form.mobile"></a-input>
                </a-form-item>
                <a-form-item label="邮箱" v-if="employeeState.update_type == 3">
                    <a-input v-model:value="employeeState.form.email"></a-input>
                </a-form-item>
                <a-form-item label="头像" v-if="employeeState.update_type == 4">
                    <kd-img-select :src="employeeState.form.pic_url" @success=" e => employeeState.form.pic_url = e "></kd-img-select>
                </a-form-item>
                <div v-if="employeeState.update_type == 5">
                    <a-form-item label="新密码">
                        <a-input v-model:value="employeeState.form.password"></a-input>
                    </a-form-item>
                    <a-form-item label="再次新密码">
                        <a-input v-model:value="employeeState.form.re_password"></a-input>
                    </a-form-item>
                </div>
			</a-form>
		</a-modal>

        <a-modal title="设置权限" 
            v-model:visible="roleState.show" 
            width="600px" 
            @ok="saveEmployeeRole" 
            @cancel="roleState.show = false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="选择角色">
                    <a-checkbox-group v-model:value="roleState.group_ids">
                        <a-checkbox :value="item.id" v-for="(item, index) in roleState.role" :key="index">
                            {{ item.title }}
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-item>
			</a-form>
		</a-modal>

        <a-modal title="选择应用" 
            v-model:visible="farmState.show" 
            width="900px" 
            @ok="saveSetFarm" 
            @cancel="farmState.show = false"
        >
			<div class="employee-info-container">
				<div
					class="eic-item "
					:class="hasCheck(item.program_id) ? 'eic-active' : ''"
					v-for="(item, index) in farmState.program.list"
					:key="index"
					@click="checkProgram(index)"
				>
                    <img class="check-img" v-if="hasCheck(item.program_id)" src="@/assets/img/icon-share-selected.png" alt="">
					<img class="eici-logo" :src="item.logo" alt="" />
					<div class="eici-info">
						<div> 
                            {{ item.program_name }} 
                            <div class="eici-status" v-if="item.is_use == 1" style="background:#00CC66">使用中</div>
                            <div class="eici-status" v-else style="background:#FF6666">已过期</div>
                        </div>
						<div>用户：{{ item.user.name }}</div>
						<div>剩余天数：{{ item.end_days }}天</div>
					</div>
				</div>
				<div style="width: 300px;height: 1px;"></div>
			</div>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="farmState.program.page"
					v-model:pageSize="farmState.program.limit"
					:total="farmState.program.count"
					@showSizeChange=" (p, e) => { getSelectProgram(farmState.program.page, e); } "
					@change="e => getSelectProgram(e, farmState.program.limit)"
				/>
			</div>
		</a-modal>
        
    </div>
</template>
<script>
import { computed, reactive, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import authModel from '@/api/saas/auth';
import userModel from '@/api/user';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			user_id: 0,
			info: null,
		})

		const opt = useRoute().query
		state.user_id = opt.user_id || 0

		getUserDetail()

        function getUserDetail(){
            userModel.getUserDetail(opt.user_id).then(res=>state.info = res)
        }

        let employeeState = useEditEmployee({state,getUserDetail})
        let roleState = useSetRole({state,getUserDetail})
        let farmState = useProgram({state})

		return{
			...toRefs(state),
            ...employeeState,
            ...roleState,
            ...farmState
		}
	}
}

//修改员工信息
function useEditEmployee(obj){
    let employeeState = reactive({
        show:false,
        update_type:0,
        form:null
    })

    function showUpdate(type){
        employeeState.form = {
            name:obj.state.info.name,
            mobile:obj.state.info.mobile,
            email:obj.state.info.email,
            pic_url:obj.state.info.pic_url,
            password: '',
			re_password: ''
        }
        employeeState.update_type = type
        employeeState.show = true
    }

    function saveEmployee(){
        let data = {
            user_id: obj.state.info.user_id,
            type: 2,
            rank: obj.state.info.rank,
            scene: employeeState.update_type == 5 ?'password' : 'edit'
        }

        if( data.scene == 'edit' ){
            data.name = employeeState.form.name
            data.email = employeeState.form.email
            data.mobile = employeeState.form.mobile
            data.pic_url = employeeState.form.pic_url
        }
        if( data.scene == 'password' ){
            data.password = employeeState.form.password
			data.re_password = employeeState.form.re_password
        }

        authModel.addOrEditEmployee( data.scene == 'edit' ?'add':'password' ,data,()=>{
            employeeState.show = false
            obj.getUserDetail()
        })
    }

    return{ employeeState ,showUpdate ,saveEmployee}
}

//设置员工权限
function useSetRole(obj){
    let roleState = reactive({
        show:false,
        group_ids:[],
        role:[],
    })

    authModel.getRole(1,99,res=>roleState.role = res.list)

    let userRoleTxt = computed(()=>{
        let str = ""
        if( roleState.role.length > 0 && obj.state.info ){
            roleState.role.forEach(item=>{
                if( obj.state.info.access.includes(item.id)){
                    str+= `[ ${item.title} ] `
                }
            })
        }
        return str
    })

    function showSetRole(){
        roleState.show = true
        if( obj.state.info.access ){
            roleState.group_ids = obj.state.info.access
        }
    }

    function saveEmployeeRole(){
        authModel.allocationRole(obj.state.info.user_id,roleState.group_ids,()=>{
            roleState.show = false
            obj.getUserDetail()
        })
    }

    return{ roleState ,showSetRole ,saveEmployeeRole,userRoleTxt }
}

//分配店铺
function useProgram(obj){
    let farmState = reactive({
        list:[],
        show:false,
        program:{
            list:[],
            page:1,
            limit:9,
            count:0
        },
        program_ids:[],     //选中的店铺
    })
    getProgramList()

    let hasCheck = computed(()=>{
        return function(id){
            return farmState.program_ids.includes(id) ? true:false
        }
    })

    function getProgramList() {
        authModel.getEmployeeShop(obj.state.user_id,res=>farmState.list = res)
    }

    function showSetFarm(){
        farmState.show = true
        if( farmState.list.length > 0 ){
            farmState.list.forEach(item=>farmState.program_ids.push(item.program_id))
        }
        getSelectProgram(1,farmState.program.limit)
    }

    function getSelectProgram(page,limit){
        programModel.getProgramList(1,page,limit,null,res=>farmState.program = {limit,...res})
    }

    function checkProgram(index){
        let id = farmState.program.list[index].program_id ,ids = toRaw(farmState.program_ids)
        ids.includes(id) ? farmState.program_ids.splice(ids.indexOf(id), 1) : farmState.program_ids.push(id) 
    }

    function saveSetFarm(){
        authModel.setEmployeeShop(obj.state.user_id,farmState.program_ids,()=>{
            getProgramList()
            farmState.show = false
        })
    }

    return{ farmState,showSetFarm,getSelectProgram ,checkProgram ,hasCheck,saveSetFarm }
}


</script>

<style lang="scss">
.employee-info{

    &-title{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .ei-avartar{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        &-update{
            width: 100%;
            text-align: center;
            background: rgba($color: #000000, $alpha: .5);
            color: #fff;
            display: inline-block;
            position: absolute;
            bottom: 0;
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            left: 0;
        }
    }  

    .ei-item{
        width: 100%;
        display: flex;
        height: 48px;
        align-items: center;
        border-bottom: 1px solid #f4f4f4;
        position: relative;

        &-title{
            width: 100px;
            color: #666;
            font-size: 12px;
        }

        &-val{
            font-weight: bold;
            font-size: 16px;
        }
    }

    .ei-update-btn{
        position: absolute;
        right: 0;
    }

    .back-btn{
        position: absolute;
        right: 24px;
        top: 24px;
    }

    .ei-farm{
        display: flex;
        flex-wrap: wrap;

        &-add{
            width: 120px;
            height: 120px;
            text-align: center;
            border: 1px dashed #999;
            color: #999;
            box-sizing: border-box;
            padding-top:40px;
            cursor: pointer;
            border-radius: 6px;
        }

        &-item{
            width: 120px;
            height: 120px;
            margin: 0 12px 12px 0;
            border-radius: 12px;
            border: 1px solid #f4f4f4;
            position: relative;

            .eifi-logo{
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
            .eifi-name{
                background: rgba($color: #000000, $alpha: .5);
                color: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-radius: 0 0 6px 6px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 12px;
            }
        }
    }

    &-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.eic-item {
			width: 270px;
			height: 100upx;
			border: 1px solid #f4f4f4;
			display: flex;
			margin: 10px 0;
			cursor: pointer;
            padding: 6px 12px;
            position: relative;

			.eici-logo {
				width: 64px;
				height: 64px;
				margin-right: 10px;
			}
			.eici-info {
                font-size: 12px;
			}
		}
        .eici-status{
            display: inline-block;
            padding: 1px 4px;
            font-size: 12px;
            transform: scale(.9);
            color: #fff;
        }
		.eic-item:hover {
			border: 1px solid #ff4544;
			color: #0074d9;
		}
		.eic-active {
			border: 1px solid #ff4544;
			color: #0074d9;
		}

        .check-img{
            width: 32px;
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
        }
	}
}

</style>
